import * as React from "react";
import { useState, useEffect } from "react";
import "../styles/globals.scss";
import "../styles/index.scss";
import "../styles/utilities.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Footer from "../components/Footer/Footer";
import {
  FaInstagram,
  FaFacebook,
  FaSpotify,
  FaBandcamp,
  FaSoundcloud,
  FaYoutubeSquare,
  FaMailBulk,
  FaUserFriends,
  FaApple,
  FaAmazon,
  FaShoppingCart,
} from "react-icons/fa";
import { SiTiktok } from "react-icons/si";
import comments from "../data/comments";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar/Navbar";

const Home = () => {
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("en-us", { month: "short" });
  const year = today.getFullYear();

  const [, setCommentsGroup] = useState(comments);

  // Generate random mood on each page load
  const moods = [
    "Vibing",
    "Chillin'",
    "Crispy",
    "Dazed",
    "Pensive",
    "Stoked",
    "Blissed Out",
    "Meditative",
  ];

  const currentMood = moods[Math.floor(Math.random() * moods.length)];

  const [mood, setMood] = useState("");

  useEffect(() => {
    setMood(currentMood);
  }, []);

  // Make Focus outlines visible for accessibility users when pressing tab

  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      // the "I am a keyboard user" key
      document.body.classList.add("user-is-tabbing");
      window.removeEventListener("keydown", handleFirstTab);
    }
  }

  if (typeof window !== `undefined`) {
    window.addEventListener("keydown", handleFirstTab);
  }

  const shuffleArray = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  };

  shuffleArray(comments);

  const randomComments = comments.slice(0, 5).map((e) => {
    return (
      <tr key={e.name} className="comment-row">
        <td>
          <h6 className="txt-highlight friend-name">{e.name}</h6>
        </td>
        <td>
          <p>{e.comment}</p>
        </td>
      </tr>
    );
  });

  return (
    <main>
      <Helmet>
        <html lang="en" />
        <title>
          Boy Golden Music | Founder, Minister and Principal Songwriter at The
          Church of Better Daze
        </title>

        <meta
          name="description"
          content="Home of Boy Golden. From stoner-boy to country-boy, blue-boy to a golden-voiced-man, Boy Golden has embodied them all. Now, as our guide, Boy Golden leads us through this next experience with kindness and humour, straight down Highway 5 and headlong into For Eden."
        />
      </Helmet>

      <Navbar />
      <div className="row profile page-content">
        <div className="col w-40 left">
          <div className="bio">
            <h1>Boy Golden</h1>
            <div className="photo-container">
              <StaticImage
                className=" profile-pic"
                src={"../images/boy-golden-for-eden-homepage-shot.jpg"}
                alt="Boy Golden Photo"
              />
            </div>
            <h4 className="txt-highlight">
              Founder, Minister, and Principal Songwriter at{" "}
              <a
                href="https://www.churchofbetterdaze.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                The&nbsp;Church of Better Daze
              </a>
            </h4>
            <p className=" center-text txt-primary  date-section ">
              <span className="txt-cream">Last Login:</span> {month} {day - 1}{" "}
              {year} <br />
              <br />
              <strong>Current Mood:</strong>
              <span className="txt-cream"> {mood}</span>
            </p>
          </div>
          <div className="general-about">
            <div className="profile-pic "></div>
            <div className="details"></div>
          </div>

          <div className="blurbs mobile">
            <div className="heading">
              <h4 className="txt-shadow-grey">
                Out Now: <i>For Eden</i>
              </h4>
              <a
                className="btn"
                href="https://sixshooterrecords.lnk.to/foredenWE"
                target="_blank"
              >
                Listen Now!
              </a>
            </div>
            <div className="inner">
              <div className="section flex-row">
                <div className="album-pic-container">
                  <StaticImage
                    className=" album-pic"
                    src={"../images/for-eden-album-cover.jpg"}
                    alt="Boy Golden For Eden album art"
                  />
                </div>
                <div className="album-description">
                  <p>
                    Utopic and wistful, Boy Golden searches for the best of all
                    possible worlds in For Eden, a continuation of his “follow
                    your art” proselytizing that began with his{" "}
                    <i>Church of Better Daze</i> debut. A counterpoint to 2023’s{" "}
                    <i>For Jimmy</i>, <i>For Eden</i> is largely a work of
                    solitude; its story begins with an SM57 and a cabin in the
                    woods. Recorded entirely to cassette, the album’s origins -
                    analog and off-the-grid - speak to the more personal,
                    self-reflective mood of the collection.
                    <br />
                    <br /> While Boy Golden’s favourite mountain road makes a
                    cameo, the album’s country waltzes, banjo and mandolin-laced
                    acoustics glow softly under the light of a pink moon. Chill
                    with intention and press play on For Eden, out July 19,
                    2024.
                  </p>
                </div>
                {/* <a
                  className="txt-black txt-center"
                  target="_blank"
                  href="https://store.sixshooterrecords.com/collections/boy-golden/products/boy-golden-for-eden-1?variant=41418331291718"
                >
                  Order <em>For Eden</em>
                </a> */}
                <br />
              </div>
            </div>
          </div>
          <div className="contact">
            <div className="heading">
              {" "}
              <h4 className="txt-pink">Connecting with Boy Golden</h4>
            </div>
            <div className="inner">
              <div className="f-row">
                <div className="f-col">
                  <div className="icon-row">
                    <FaUserFriends />{" "}
                    <a
                      href="https://laylo.com/boygolden"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Join Congregation
                    </a>
                  </div>
                </div>
                <div className="f-col">
                  {" "}
                  <div className="icon-row">
                    <FaMailBulk /> <Link to="/about/#contact">Contact</Link>
                  </div>
                </div>
              </div>
              <div className="f-row">
                <div className="f-col">
                  <div className="icon-row">
                    <FaInstagram />{" "}
                    <a
                      href="https://www.instagram.com/boygolden/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </div>
                </div>
                <div className="icon-row">
                  <FaFacebook />{" "}
                  <a
                    href="https://www.facebook.com/boygolden"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </div>
              </div>
              <div className="f-row">
                <div className="icon-row">
                  <FaSoundcloud />{" "}
                  <a
                    href="https://soundcloud.com/user-87012428"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Soundcloud
                  </a>
                </div>
                <div className="f-col">
                  <div className="icon-row">
                    <FaBandcamp />{" "}
                    <a
                      href="https://yaboygolden.bandcamp.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Bandcamp
                    </a>
                  </div>
                </div>
              </div>
              <div className="f-row">
                <div className="f-col">
                  {" "}
                  <div className="icon-row">
                    <FaYoutubeSquare />{" "}
                    <a
                      href="https://www.youtube.com/channel/UCFuCZ_8LFQa2wTbt-RIWUBQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Youtube
                    </a>
                  </div>
                </div>
                <div className="f-col">
                  <div className="icon-row">
                    <FaSpotify />{" "}
                    <a
                      href="https://open.spotify.com/artist/4oNZapwLKDfR92AX7LbRk1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Spotify
                    </a>
                  </div>
                </div>
              </div>
              <div className="f-row">
                <div className="f-col">
                  {" "}
                  <div className="icon-row">
                    <FaApple />{" "}
                    <a
                      href="https://music.apple.com/ca/artist/boy-golden/392029780"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Apple Music
                    </a>
                  </div>
                </div>
                <div className="f-col">
                  <div className="icon-row">
                    <FaAmazon />{" "}
                    <a
                      href="https://music.amazon.ca/artists/B01EC1P2O6/boy-golden?marketplaceId=A2EUQ1WTGCTBG2&musicTerritory=CA"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Amazon Music
                    </a>
                  </div>
                </div>
              </div>
              <div className="f-row">
                <div className="f-col">
                  {" "}
                  <div className="icon-row">
                    <SiTiktok />{" "}
                    <a
                      href="https://www.tiktok.com/@ya.boy.golden?"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      TikTok
                    </a>
                  </div>
                </div>
                <div className="f-col">
                  <div className="icon-row">
                    <FaShoppingCart />{" "}
                    <a
                      href="https://store.boygolden.ca/collections/boy-golden"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Merch Store
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="url-info  center-text  bg-grey">
            <a
              href="https://boygolden.substack.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>
                Read The Latest <em className="">Ministerial Musings!</em>
              </h4>
            </a>
          </div>
          <div className="table-section">
            <div className="heading">
              <h4 className="txt-pink">
                <i>For Eden</i> Scrapbook / Notes
              </h4>
            </div>
            <div className="inner">
              <table className="details-table" cellSpacing="3" cellPadding="3">
                <tbody>
                  <tr>
                    <td>
                      <h5>Now Playing</h5>
                      <p>
                        Nick Drake - Pink Moon, Gillian Welch - The Harrow & The
                        Harvest , Bruce Springsteen - Nebraska, Bon Iver - For
                        Emma, Forever Ago, Jessica Pratt - Self-Titled, Karen
                        Dalton - It's So Hard To Tell Who's Going To Love You
                        Best, Lucinda Williams - Essence
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h5>Now Reading</h5>

                      <p>
                        Kafka on the Shore - Haruki Murakami, Letters To A Young
                        Poet - Rilke, Pachinko - Min Jin Lee, The Overstory -
                        Richard Powers, Jitterbug Perfume - Tom Robbins, Zen and
                        the Art of Motorcycle Maintenance - Robert Pirsig
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Now Watching</h5>
                      <p>
                        Star Wars, Adventure Time, Bob's Burgers, Trailer Park
                        Boys
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Currently Inspired By:</h5>
                      <p>
                        My friends, the prairie landscape, early mornings,
                        mushrooms, tape machines, 12-string guitars, quinoa,
                        sweet solitude, solid community, the tao of all things
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col right">
          <div className="blurbs desktop">
            <div className="heading">
              <h4 className="txt-shadow-grey">
                Out Now: <i>For Eden</i>
              </h4>
              <a
                className="btn"
                href="https://sixshooterrecords.lnk.to/foredenWE"
                target="_blank"
              >
                Listen now!
              </a>
            </div>
            <div className="inner">
              <div className="section flex-row">
                <div className="album-pic-container">
                  <StaticImage
                    className=" album-pic"
                    src={"../images/for-eden-album-cover.jpg"}
                    alt="Boy Golden For Eden album art"
                  />
                </div>
                <div className="album-description">
                  <p>
                    Utopic and wistful, Boy Golden searches for the best of all
                    possible worlds in For Eden, a continuation of his “follow
                    your art” proselytizing that began with his{" "}
                    <i>Church of Better Daze</i> debut. A counterpoint to 2023’s{" "}
                    <i>For Jimmy</i>, <i>For Eden</i> is largely a work of
                    solitude; its story begins with an SM57 and a cabin in the
                    woods. Recorded entirely to cassette, the album’s origins -
                    analog and off-the-grid - speak to the more personal,
                    self-reflective mood of the collection. <br />
                    <br /> While Boy Golden’s favourite mountain road makes a
                    cameo, the album’s country waltzes, banjo and mandolin-laced
                    acoustics glow softly under the light of a pink moon. Chill
                    with intention and press play on For Eden, out July 19,
                    2024.
                  </p>
                </div>
                {/* <a
                  className="txt-black txt-center"
                  target="_blank"
                  href="https://store.sixshooterrecords.com/collections/boy-golden/products/boy-golden-for-eden-1?variant=41418331291718"
                >
                  Order <em>For Eden</em>
                </a> */}
                <br />
                <br />
              </div>
            </div>
          </div>
          {/* <div className="blog-preview bg-grey center-text">
            <h4>
              <a
                href="https://store.sixshooterrecords.com/collections/boy-golden/products/boy-golden-for-eden-1?variant=41418331291718"
                target="_blank"
              >
                Order <em>For Eden </em>on Vinyl, CD, or Digital
              </a>
            </h4>
          </div> */}

          <div className="albums" id="comments">
            <div className="heading ">
              <h4>Albums</h4>
            </div>
            <div className="image-container">
              <div className="image-wrapper">
                <h3>Church of Better Daze</h3>
                <StaticImage
                  className="album-thumbnail"
                  src="../images/cobd-album-cover.jpg"
                  alt="Church of Better Daze Album Cover"
                />
                <div className="button-container">
                  <a
                    className="btn btn--transparent"
                    target="_blank"
                    href="https://SixShooterRecords.lnk.to/COBDWE"
                  >
                    Stream
                  </a>
                  <a
                    className="btn btn--transparent"
                    target="_blank"
                    href="https://store.sixshooterrecords.com/collections/boy-golden/products/church-of-better-daze?variant=39350339502150"
                  >
                    Purchase Vinyl/CD
                  </a>
                </div>
              </div>
              <div className="image-wrapper">
                <h3>For Jimmy (EP)</h3>
                <StaticImage
                  className="album-thumbnail"
                  src="../images/for-jimmy-album-cover.jpg"
                  alt="For Jimmy EP Cover"
                />
                <div className="button-container">
                  <a
                    className="btn btn--transparent"
                    target="_blank"
                    href="https://SixShooterRecords.lnk.to/jimmyWE"
                  >
                    Stream
                  </a>
                  <a
                    className="btn btn--transparent"
                    target="_blank"
                    href="https://store.sixshooterrecords.com/collections/boy-golden/products/pre-order-boy-golden-for-jimmy?variant=40602915176518"
                  >
                    Purchase Vinyl/CD
                  </a>
                </div>
              </div>
              <div className="image-wrapper">
                <h3>For Jimmy (Deluxe)</h3>
                <StaticImage
                  className="album-thumbnail"
                  src="../images/for-jimmy-album-cover-deluxe.jpg"
                  alt="For Jimmy Deluxe Album Cover"
                />
                <div className="button-container">
                  <a
                    className="btn btn--transparent"
                    target="_blank"
                    href="https://SixShooterRecords.lnk.to/forjimmydeluxeWE"
                  >
                    Stream
                  </a>
                  <a
                    className="btn btn--transparent"
                    target="_blank"
                    href="https://store.sixshooterrecords.com/collections/boy-golden/products/pre-order-boy-golden-for-jimmy?variant=40602990575686"
                  >
                    Purchase Vinyl/CD
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="blog-preview bg-grey center-text">
            <h4>
              <a href="https://laylo.com/boygolden" target="_blank">
                Sign up to receive early tickets, exclusive merch, never-seen
                before content and more!
              </a>
            </h4>
          </div>

          <div className="friends">
            <div className="heading">
              <h4 className="txt-shadow-grey">Boy Golden's Friends</h4>
            </div>
            <div className="inner">
              <p>
                <strong>
                  Boy Golden has <span className="count">3</span> friends
                </strong>
              </p>
              <div className="friends-grid">
                <div className="person">
                  <a
                    href="https://en.wikipedia.org/wiki/Tom_Anderson"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>Tom</p>
                  </a>
                  <StaticImage src="../images/tom.jpg" alt="Tom from myspace" />
                </div>
                <div className="person">
                  <a
                    href="https://www.churchofbetterdaze.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>Church of Better Daze</p>
                  </a>
                  <StaticImage
                    src="../images/albumLogo.jpg"
                    alt="church of better daze logo"
                  />
                </div>
                <div className="person">
                  <a
                    href="https://sixshooterrecords.com/artists/boy-golden/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>Six Shooter Records</p>
                  </a>
                  <StaticImage
                    src="../images/sixShooterLogo.png"
                    alt="six shooter records logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Home;
